export const homePageBtn = [
  "Litrol SC",
  "Canion",
  "Serra RS",
  "Serra SC",
  "Floripa",
  "Praia",
  "Rosa Norte",
  "Praia",
  "Rosa Norte",
  "Canion",
  "Litrol SC",
  "Floripa",
];
