import React, { useEffect } from "react";
import "./style.scss";
import { FormControl, FormControlLabel, Checkbox, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Button } from "@mui/material";
import Layout from "../../Layout/index";


const Login = () => {
  const handleNExt=()=>{
    window.location.href = "/register"
  }

  useEffect(() => {
    window.addEventListener("click", handleNExt);
    return () => {
      window.removeEventListener("click", handleNExt);
    };
  });
  
  return (
    <Layout>

      <div className="wrapper">
        <div className="login-wrapper">
          <div className="login-left">
            <h2 className="login-title">Já sou cliente</h2>
            <div className="field-wraper">
              <TextField
                id="email"
                label="Email ou CPF"
                variant="outlined"
                className="txt"
                size="small"
              />
              <TextField
                id="senha"
                label="Senha"
                variant="outlined"
                className="txt"
                size="small"
              />
              <Button variant="contained" className="login-btn">
                ENTRAR
              </Button>
              <p className="forgot-password">Esqueceu sua senha?</p>
              <h4 className="ou">ου</h4>
              <p>
                <span className="google-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="48px"
                    height="48px"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                </span>
                Entrar com a conta Google
              </p>
            </div>
          </div>
          <div className="login-right">
            <h2 className="login-title">Criar uma conta</h2>
            <div className="field-wraper">
              <TextField
                id="Nome"
                label="Nome"
                variant="outlined"
                className="txt"
                size="small"
              />
              <TextField
                id="Email"
                label="Email"
                variant="outlined"
                className="txt"
                size="small"
              />
              <TextField
                id="Confirmar Email"
                label="Confirmar Email"
                variant="outlined"
                className="txt"
                size="small"
              />
              <TextField
                id="CPF"
                label="CPF"
                variant="outlined"
                className="txt"
                size="small"
              />
              <FormControl fullWidth className="gender">
                <InputLabel id="demo-simple-select-label" size="small" >Gênero</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Gênero" size="small" className="selectbox">
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="Senha"
                label="Senha"
                variant="outlined"
                className="txt"
                size="small"
              />
              <div className="terms">
                <span>
                  <FormControlLabel control={<Checkbox defaultChecked />} />
                </span>
                <span>
                  Concordar com <b>Termos de Uso e Políticas de Privacidade</b>
                </span>
              </div>
              <Button variant="contained" className="register-btn">
                FINALIZAR CADASTRO
              </Button>
              <div className="register-link">
                <a href="#">Seja um Anunciante <span className="arrow"></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Login;
